import React, { useEffect, useState } from "react"; 
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";  

function PrivacyPolicy() { 
  return (
    <>
      <Header />
      <section className="ex_box p70 pn_page_pt exc_sec pb-5">
        <div className="container  position-relative mb-md-5">
          <h2 className="hadding text-center mb-4 mb-md-5">
          Privacy   <span className="t_gr">Policy</span>
          </h2>
<h3>Privacy Policy</h3>
 <p>This Privacy Policy ("Policy") governs the collection, use, and protection of personal information by UKC ANCHOR LTD ("we," "us," or "our") when you visit our website ‘ukccoin.io’. By accessing or using our website, you agree to the collection and use of your personal information as described in this Policy.
 </p>

 <h3 className="mt-4"> 1. Information Collection</h3>
 <p>When you visit our website, we may collect the following types of information: </p>
 <p><b>1.1 Personal Information: </b> This includes your name, email address, contact information, and any other information you voluntarily provide through contact forms or account registration.
 </p>
 <p><b>1.2 Log Data:</b> We collect information that your browser sends when you visit our website, such as your IP address, browser type, operating system, pages visited, duration of visit, and other statistics.
 </p>
 <p><b>1.3 Cookies and Similar Technologies:</b>  We use cookies and similar tracking technologies to enhance your experience on our website. Refer to our Cookies Policy for detailed information.
 </p>

 
 <h3 className="mt-4">2. Use of Information
 </h3>
 <p> We may use the collected information for the following purposes:
 </p>
 <p><b>2.1 Service Provision and Improvement: </b> We use your information to understand your needs and enhance your experience on our website, including analyzing usage patterns, customizing content, and improving website functionality.
 </p>
 <p><b>2.2 Communication:</b>  We may use your contact information to respond to your inquiries, provide customer support, send important notices, and inform you about our services and promotions.
 </p>
 <p><b>2.3 Personalization:</b>  We may use your information to personalize your experience on our website, such as displaying relevant content or advertisements.
 </p>
 <p><b>2.4 Legal Compliance:</b>  We may process your information to comply with applicable laws, regulations, or legal requests. </p>

 <h3 className="mt-4"> 
 3. Data Security and Retention</h3>
 <p>We are committed to protecting your personal information and employ appropriate technical and organizational measures to prevent unauthorized access, alteration, disclosure, or destruction of your data. We retain your personal information only as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law. </p>

 <h3 className="mt-4"> 4. Third-Party Disclosure </h3>
 <p>
 We may share your personal information with trusted third parties who assist us in operating our website or providing services to you. These parties are obligated to maintain the confidentiality of your information and use it solely for the specified purposes. We may also disclose your information to comply with applicable laws, regulations, or legal processes, or to protect our rights, property, or safety.</p>

 

 <h3 className="mt-4">  5. Your Choices and Rights</h3>
 <p> 
You have certain rights regarding your personal information. You can request access, correction, or deletion of your information, and you may object to or restrict certain processing activities. To exercise these rights, please contact us using the information provided below.
</p>

 <h3 className="mt-4">6. Updates to this Policy </h3>
 <p>
We reserve the right to update this Policy from time to time. Any changes will be posted on our website, and the "Last updated" date at the top of the Policy will be revised accordingly. It is your responsibility to review this Policy periodically to stay informed about our handling of your personal information.</p>

 <h3 className="mt-4">7. Contact Information </h3>
 <p>


If you have any questions, concerns, or requests regarding this Privacy Policy or our practices, please contact us at support@ukccoin.io. </p>

        
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
