import React, { useEffect, useState } from "react";
import Footer from "../../widgets/Footer";
import AsSeen from "./AsSeen";
import Banner from "./Banner";
import BuySell from "./BuySell";
import Faq from "./Faq";
import MiddleBanner from "./MiddleBanner";
import Signup from "./Signup";
import YouTubeLink from "./YouTubeLink";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../../widgets/Header";
import { useLocation, useParams } from "react-router-dom";
import { useSetCheckReferralUrlMutation } from "../../../redux/user";

function Home() {
  const { referral_code } = useParams();
  const [setCheckReferralUrl] = useSetCheckReferralUrlMutation();
  const [referralStatus, setReferralStatus] = useState(true);
  const [referralCode, setReferralCode] = useState("")
  const location = useLocation();

  useEffect(() => {
    if (location.hash == "") {
      scrollTop();
    }
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 100,
    });
  });

  useEffect(() => {
    if(referral_code){
      setCheckReferralUrl({referral_code}).then((result) => {
        if (result.data.status) {
          setReferralStatus(result.data.status)
          localStorage.setItem("referral_code",referral_code)
          setReferralCode(referral_code)
        }
        else{
          setReferralStatus(result.data.status)
        }
      });
    }
    
  }, [referral_code])

  useEffect(() => {
     if(localStorage.getItem("referral_code")){
      setReferralCode(localStorage.getItem("referral_code"))
     }
  }, [])

  return (
    <>
    {!referralStatus ?(
      <h2 className="text-center">Invalid Url</h2>
    ): (
      <>
        <Header />
        <div className="ovh">
          <Banner />
          <BuySell referral_code={referralCode}/>
        </div>
        <AsSeen />
        <YouTubeLink />
        <MiddleBanner />
        <Signup referral_code={referralCode}/>
        <Faq />
        <Footer />
      </>
    )  }
  </>
  );
}

export default Home;
