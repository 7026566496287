import React, { useEffect, useRef } from 'react'
import { useAuth } from '../../../AuthContext'
import { useGetTransactionQuery } from '../../../redux/transactionApi'
import { useGetUserDetailQuery } from '../../../redux/user'
import { referralLink } from '../../constant/BaseUrl'
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {

  projectId,

} from "../../constant/enum";
import { avalancheChainId, avalancheRpcUrl, explorerUrlAvalanche } from '../../constant/avalanche'
import { explorerUrlPolygon, polygonChainId, polygonRpcUrl } from '../../constant/poly'
import { binanceChainId, bscRpcUrl, explorerUrlBsc } from '../../constant/bsc'
import { ethChainId, EthRpcUrl, explorerUrlEth } from '../../constant/eth'
import { useLocation } from 'react-router-dom'

function Referral() {
  const {authenticated}=useAuth()
  const {data,refetch}=useGetUserDetailQuery()
  const location = useLocation();
  const referRef = useRef(null);
  const { data:record,refetch:transactionrefetch } = useGetTransactionQuery();

  useEffect(() => {
     if(authenticated){
      refetch()
      transactionrefetch()
     }
  }, [authenticated])
  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#refer":
        scrollToRef(referRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: explorerUrlEth,
      rpcUrl: EthRpcUrl,
    },
    {
      chainId: polygonChainId,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: explorerUrlPolygon,

      rpcUrl: polygonRpcUrl,
    },

    {
      chainId: avalancheChainId,
      name: "Avalanche",
      currency: "AVAX",
      explorerUrl: explorerUrlAvalanche,
      rpcUrl: avalancheRpcUrl,
    },
  ];

  const metadata = {
    name: "ukccoin",
    description: "ukc",
    url: "https://ukccoin.com/",
    icons: ["https://ukccoin.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const copyToClipboard = () => {
    if (data?.referral_id) {
      toast.dismiss();
      toast.success("Referral link copied successfully");
      copy(referralLink + data?.referral_id);
    }
  };
  

  return (
    <div className='bonus_man' id='earn2' ref={referRef} >
      <div className="container-fluid ">
      <div className="row ">
          <div className="col-md-5 text-center m-auto z_11" data-aos="fade-up-right">
            
          <h2 className="fmenda bonus_5 mb-0   ttu" data-aos="zoom-out-up">
          Earn more ukc by referring your friends and community
          </h2>

          <div class="refrail_link  mt-3 mt-md-5 mb-4 mb-md-5"><input type="text" class="form-control" placeholder=" Connect Wallet for Referral Link" value={authenticated ?referralLink+data?.referral_id:""}/><button class="btn btn2 " onClick={copyToClipboard} disabled={data?.referral_id?false:true}> Copy</button>
          </div>
          {/* <button class="btn connect_wallet_btn "> Connect Wallet</button> */}
          <div class="d-flex justify-content-center">
          <w3m-button balance="hide" />
          </div>
         

          <p className='sid mt-md-5 mt-4 pt-3 mb-5'><span>Learn how referral works</span></p>
          <a class="btn connect_wallet_btn " href='https://ukc-coin.gitbook.io/ukc-whitepaper' target='_blank'> Whitepaper</a> 
          {/* <div className="social_icon social_icon2 mt-4 mt-md-5"><a href="https://x.com/ukc_coin" target="_blank"><i className="bi bi-twitter-x"></i></a><a href="https://www.instagram.com/ukccoin?igsh=cHpkOTNxMmJ3dGVz" target="_blank"> <i className="bi bi-instagram"></i></a><a href="https://t.me/UKCCoinOfficialGroup" target="_blank"><i className="bi bi-telegram"></i></a></div> */}
          </div>
          <div className="col-md-6 ms-auto">
            <h1 className="fmenda bonus_5 mb-0   ttu" data-aos="zoom-out-up">Earn up to <span className=' ttu color1'>5%
            bonus</span>  from all referred investors!</h1>
            <img className="bbi1"  src="/assets/img/bbi1.png" alt="tm" />
            <div className="row">
            <div className="col-md-5">
            
          </div>
          </div>
          </div>
      </div>
      <img className="bbi2 "  src="/assets/img/bbi2.png" alt="tm" />
      <img className="bbi3 "  src="/assets/img/bbi3.png" alt="tm" />
    </div>
    </div>
  )
}

export default Referral
