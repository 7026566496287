import { checkReferralUrlApi, checkUserApi, getUserDashboardApi, getUserDetailapi, userRegisterApi, userTempWalletAddressApi } from "../components/constant/api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setUserRegister: builder.mutation({
      query: (post) => ({
        url: userRegisterApi,
        method: "POST",
        body:post
      }),
    }),
    setCheckUser: builder.mutation({
      query: (post) => ({
        url: checkUserApi,
        method: "POST",
        body:post
      }),
    }),
    setTempUserWallet: builder.mutation({
      query: (post) => ({
        url: userTempWalletAddressApi,
        method: "POST",
        body:post
      }),
    }),
    setCheckReferralUrl: builder.mutation({
      query: (post) => ({
        url: checkReferralUrlApi,
        method: "POST",
        body:post
      }),
    }),
    getUserDetail: builder.query({
      query: () => ({
        url: getUserDetailapi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? {} :{};
      },
      providesTags: (_) => ["transaction"],
    }),
  }),
});

export const {useSetCheckUserMutation,useSetTempUserWalletMutation,useSetUserRegisterMutation,useSetCheckReferralUrlMutation,useGetUserDetailQuery} = userApi;