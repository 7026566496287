import React,{useEffect,useState} from "react";
import { useAuth } from "../../../AuthContext";
import { useGetRecentActivityQuery } from "../../../redux/transactionApi";
import moment from "moment";

function RecentActivicity() {
  // const Transactions = [
  //   { from_address: "0x4A684e0DAef5684beCE38C44A8D286d1D0a5cA0b", usdt_amount: 18160, createdAt: "4th Nov, 02:55 PM", ukc_token: 1816000 },
  //   { from_address: "0x517823cC996fd08bE93a9b8c89156Fb1E9091D3A", usdt_amount: 12900, createdAt: "4th Nov, 02:26 PM", ukc_token: 1290000 },
  //   { from_address: "0x84D43B5764272DbDAD04F025feA96bF84Bb745a0", usdt_amount: 3000, createdAt: "4th Nov, 12:15 PM", ukc_token: 300000 },
  //   { from_address: "0x7389Ea73Bc5c1D61EDd6Ee886117146Da0779c0a", usdt_amount: 15000, createdAt: "4th Nov, 11:55 AM", ukc_token: 1500000 },
  //   { from_address: "0x1763108d8A4090FB1D09cB6F2918a34f54796eAF", usdt_amount: 9320, createdAt: "3rd Nov, 08:56 AM", ukc_token: 932000 },
  //   { from_address: "0x8291eF5a08B1Cc7095ED5e0899aaaEFB274E9713", usdt_amount: 4800, createdAt: "3rd Nov, 05:27 AM", ukc_token: 480000 },
  //   { from_address: "0x9Ef28dE4DFAdcAcc45432e59278356fe46d5ca6F", usdt_amount: 27000, createdAt: "3rd Nov, 07:18 AM", ukc_token: 2700000 },
  //   { from_address: "0x0E27e84cd15A5Be3f6D29a7a86Cea23b5927E131", usdt_amount: 33700, createdAt: "3rd Nov, 06:05 PM", ukc_token: 3370000 },
  //   { from_address: "0x5bBDc2065a2DE1f4A8cC087E4E0b07C919d4994E", usdt_amount: 21000, createdAt: "3rd Nov, 11:30 PM", ukc_token: 2100000 },
  //   { from_address: "0x0F0B1b86616d2Ed7e25fD2230B544BEacD004f16", usdt_amount: 6500, createdAt: "2nd Nov, 11:56 PM", ukc_token: 650000 }
  // ];
  

  // const [data,setData]=useState(Transactions)
  // console.log(data,"data===")

  const {data}=useGetRecentActivityQuery()

  

  return (
    <>
      <div className="rc_box">
        <h4 className="text-center mb-3">RECENT ACTIVITY</h4>
        <div className="rc_box_scrool">
          {data?.length>0?
          data?.map((list)=>{
            return (
              <div className="rc_box_in">
              <div className="rc_box_in_b row">
                <div className="col-md-7">
                  <span className="color1 w100px">UKCCOIN: </span>
                  <span className="">{list.ukc_token} </span>
                </div>
                <div className="col-md-5">
                  <span className="ps-md-2">USD: </span>
                  ${list.usdt_amount}
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <span className="wb w100px">Wallet: </span>
                  <span className="">{list?.from_address && `${list?.from_address?.substring(0, 7)}...${list?.from_address.substring(list?.from_address?.length - 7)}`} </span>
                </div>
                <div className="col-md-5">
                  <span className="ps-md-2">Time: </span>
                  {moment(list.createdAt).format("Do MMM, hh:mm A")}
                  {/* {list.createdAt} */}
                </div>
              </div>
            </div>
            )
          }):<h3 className="text-center">No record found</h3>}
        
         
        </div>
      </div>
    </>
  );
}

export default RecentActivicity;
