import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetLiveTokenDateQuery, useGetYoutubeUrlQuery } from "../../../redux/additionalApi";

function Banner() {
  const {data}=useGetLiveTokenDateQuery()
  const {data:youtube,isLoading}=useGetYoutubeUrlQuery()
  const [date, setDate] = useState("")
  const [youtubeUrl, setYoutubeUrl] = useState("")
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if(youtube && youtube.length>0){
      setYoutubeUrl(youtube[0]?.youtube_link)
    }
  }, [youtube])
  

   useEffect(() => {
      if(data && data.length>0){
        setDate(data[0]?.live_date)
      }
   }, [data])
   
   useEffect(() => {
    if(date){

      const interval = setInterval(() => {
        const dubaiDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Dubai" }));
        const now = dubaiDate.getTime();
        // const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Dubai" }).getTime();
        const startTime = new Date(
          date
        ).getTime();
   
        let t;
        t = startTime - now;
        if (t <= 0) {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
          const days = Math.floor(t / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((t % (1000 * 60)) / 1000);
          setTimeLeft({ days, hours, minutes, seconds });
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [date]);

  const memoizedTimeLeft = useMemo(() => {
    return timeLeft;
  }, [timeLeft]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const setting1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="new_section section_p pt-md-5 pt-3">
        <div className="container-fluid mt-1">
          <div className="row align-items-center   text-md-start">
            <div className="  col-md-6">
            {/* <div className="timer2 mb-3  text-md-start text-center" data-aos="fade-right">
            {timeLeft.days==0 && timeLeft.hours==0 && timeLeft.minutes==0 && timeLeft.seconds==0? 
            !isLoading?<div className="d-sm-flex  align-items-center">
              <h4 className="mb-md-0">UKC EVENT IS LIVE:</h4>
              <a href={youtubeUrl} className="btn m-auto btn_live">LIVE</a>
            </div>:"":<div className="d-sm-flex align-items-center">
              <h4 className="mb-md-0">UKC LAUNCH EVENT:</h4>
              <div className="timer_in d-inline-flex m-auto text-center fmenda "> 
                <span>{memoizedTimeLeft.days.toString().padStart(2, "0")} <small>Days</small></span>
                <span>{memoizedTimeLeft.hours.toString().padStart(2, "0")}<small>Hrs</small></span>
                <span>{memoizedTimeLeft.minutes.toString().padStart(2, "0")}<small>Mins</small></span>
                <span> {memoizedTimeLeft.seconds.toString().padStart(2, "0")}<small>Secs</small></span> 
                </div>
            </div>} 
            </div> */}
              <p className="home_manhadding h5 color5 fw300 re14" data-aos="fade-right">
                //UKCCOIN PHASE 1
              </p>
            </div>
            <div
              className="col-md-6 ms-auto col-6 text-end position-relative"
              data-aos="fade-left"
            >
              <img
                src="/assets/img/cricket.png"
                alt="cricket"
                className="img-fluid "
              />
              {/* <div className="cricket_btn">
                <span className="fmenda">CRICKET MEETS CRYPTO</span>
                THE ULTIMATE CRYCKET FAN TOKEN
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="hero position-relative">
        <img
          src="/assets/img/hero.png"
          alt="cricket"
          className="w100"
          data-aos="fade-up"
        />
        <img
          src="/assets/img/u_big.png"
          alt="u"
          className="position-absolute u_big"
          data-aos="fade-left"
        />
        <img
          src="/assets/img/cointop.png"
          alt="u"
          className="position-absolute cointop"
          data-aos="fade-left"
        />
        <div className="container-fluid pt-5">
          <p className="fw300 mb-4 h5" data-aos="fade-right">
            UKCCOIN isn’t just a<br /> cryptocurrency; it’s a revolution in
            <br /> fan engagement.
          </p>
          <a href="/#buysell" className="btn w_btn" data-aos="fade-right">
            <span className="d-flex align-items-center">
              Buy now <i className="bi bi-arrow-right-short b_icon"></i>
            </span>{" "}
          </a>
        </div>
        <div className="big_b_conntent" data-aos="fade-right">
          <div className="container-fluid">
            <p className="MendaMed color5 h4">
              Designed exclusively for cricket lovers, <br />
              UKCCOIN transforms how <br />
              fans interact with the game
            </p>
            <div className="d-flex ttu max_h5 mt-md-5 mt-4">
              <h6>Mass & In-Person Events </h6>
              <h6>Conreferces & Workshops </h6>
              <h6>Commercial & Promo Shows</h6>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative ">
        <div className="scrool_logo1 position-absolute MendaMed">
          <Slider {...setting1}>
            <h5 className="s_line1 m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
          </Slider>
        </div>
      </section>
      <section className="position-relative ">
        <div className="scrool_logo2  MendaMed">
          <Slider {...settings}>
            <h5 className="s_line1 m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Banner;
