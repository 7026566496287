import {
  avalancheChainId,
  avalancheCurrency,
  avalancheRpcUrl,
  avala_abi_usdc,
  avala_abi_usdt,
  avala_ta_usdc,
  avala_ta_usdt,
  avala_ukc_abi,
  avala_ukc_address,
  explorerUrlAvalanche,
  networkavala,
} from "./avalanche";
import {
  binanceChainId,
  bscCurrency,
  bscRpcUrl,
  // bsc_abi,
  bsc_abi_usdc,
  bsc_abi_usdt,
  bsc_abi_wbtc,
  bsc_abi_weth,
  bsc_ta_usdc,
  bsc_ta_usdt,
  bsc_ta_wbtc,
  bsc_ta_weth,
  bsc_ukc_abi,
  bsc_ukc_address,
  explorerUrlBsc,
  networkbsc,
} from "./bsc";
import {
  ethChainId,
  ethCurrency,
  EthRpcUrl,
  // eth_abi_common,
  eth_abi_usdc,
  eth_abi_usdt,
  eth_abi_wbtc,
  eth_ta_usdc,
  eth_ta_usdt,
  eth_ta_wbtc,
  eth_ukc_abi,
  eth_ukc_address,
  explorerUrlEth,
  networketh,
} from "./eth";
import {
  explorerUrlPolygon,
  networkpoly,
  polyCurrency,
  polygonChainId,
  polygonRpcUrl,
  poly_abi_usdc,
  poly_abi_usdt,
  poly_ta_usdc,
  poly_ta_usdt,
  poly_ukc_abi,
  poly_ukc_address,
} from "./poly";
import { solCurrency } from "./sol";
import { tronCurrency } from "./tron";

export const userTypeEnum = {
  User: "3",
  Admin: "1",
  Staff: "2",
};

export const transTypeEnum=[
  {
    value:"Buy",
    key:"Buy"
  },
  {
    value:"Purchase Bonus",
    key:"Purchase Bonus"
  },
  {
    value:"Referral Bonus",
    key:"Referral Bonus"
  },
  {
    value:"Admin Bonus",
    key:"Admin Bonus"
  },
]

export const getLivePriceURL="https://api.binance.com/api/v3/ticker/price?symbol="

export const charBtype = "WB";
export const charEtype = "WE";
export const commonTokenPriceEnum = 1;
export const coinTypeEnum = "1";
export const tokenTypeEnum = "2";
export const minUSDT=5
export const minTRONUSDT=20

export const binanceChainEnum = "0";
export const binanceImage="/assets/img/binance.png"

export const chainTypeEnum = [
  {
    key: "Binance",
    value: "0",
    custom:0,
    image:"/assets/img/binance.png"
  },
  {
    key: "Eth",
    value: "1",
    custom:0,
    image:"/assets/img/eth.png"
  },
  {
    key: "Polygon",
    value: "2",
    custom:0,
    image:"/assets/img/Polygon.png"
  },
  {
    key: "Avalanche",
    value: "3",
    custom:0,
    image:"/assets/img/avalanche.png"
  },
  {
    key: "TRON",
    value: "4",
    custom:1,
    image:"/assets/img/tron.png"
  },
  {
    key: "SOLANA",
    value: "5",
    custom:1,
    image:"/assets/img/sol.png"
  },
];

export const currencyTypeEnum = {
  0: bscCurrency,
  1: ethCurrency,
  2: polyCurrency,
  3: avalancheCurrency,
  4:tronCurrency,
  5:solCurrency,
};

export const tokenAddressesEnum = {
  0: { 1: bsc_ta_wbtc, 2: bsc_ta_weth, 3: bsc_ta_usdt, 4: bsc_ta_usdc },
  1: { 1: eth_ta_wbtc, 2: eth_ta_usdt, 3: eth_ta_usdc },
  2: { 1: poly_ta_usdt, 2: poly_ta_usdc },
  3: { 1: avala_ta_usdt, 2: avala_ta_usdc },
};

export const getAbiEnum = (chain, currency) => {
  const abiMapping = {
    0: {
      1:bsc_abi_wbtc,
      2:bsc_abi_weth,
      3:bsc_abi_usdt,
      default: bsc_abi_usdc,
    },
    1: {
      // 0: eth_abi_common,
      1: eth_abi_wbtc,
      3: eth_abi_usdc,
      default: eth_abi_usdt,
    },
    2: {
      // 0: eth_abi_common,
      1: poly_abi_usdt,
      default: poly_abi_usdc,
    },
    3: {
      // 0: eth_abi_common,
      1: avala_abi_usdt,
      default: avala_abi_usdc,
    },
  };

  // Check if chain exists and if it's an object, handle currency selection
  const abi = abiMapping[chain];
  if (typeof abi === "object") {
    return abi[currency] || abi.default;
  }
  return abi;
};

export const getFormattedBalanceToken = (balance, chain, currency) => {
  const decimals = chain == 1 && currency == 1 ? 8 : 6; // Handle decimals based on chain and currency
  const divisor = chain == 0 ? 10 ** 18 : 10 ** decimals; // Determine divisor based on chain
  console.log(balance,"divisor",(Number(balance) / divisor))
  return (Number(balance) / divisor).toFixed(8); // Format the balance
};

export const getRpcByChain = {
  0: bscRpcUrl,
  1: EthRpcUrl,
  2: polygonRpcUrl,
  3: avalancheRpcUrl,
};

export const ukcChainAddress = {
  0: bsc_ukc_address,
  1: eth_ukc_address,
  2: poly_ukc_address,
  3: avala_ukc_address,
};

export const ukcChainAbi = {
  0: bsc_ukc_abi,
  1: eth_ukc_abi,
  2: poly_ukc_abi,
  3: avala_ukc_abi,
};

export const projectId = "1d8e553b04494fc86b01065d081d9e93";

export const chainConfigs = [
  {
    id: 0,
    name: "Binance Smart Chain",
    network: networkbsc,
    chainName: "Binance Smart Chain Testnet",
    rpcUrl: bscRpcUrl,
    explorerUrl: explorerUrlBsc,
    nativeCurrency: "Binance Coin",
    symbol: "BNB",
    chainId: binanceChainId,
  },
  {
    id: 1,
    name: "Ethereum",
    network:networketh,
    chainName: "Sepolia Testnet",
    rpcUrl: EthRpcUrl,
    explorerUrl: explorerUrlEth,
    nativeCurrency: "Sepolia ETH",
    symbol: "ETH",
    chainId: ethChainId,
  },
  {
    id: 2,
    name: "Polygon",
    network: networkpoly,
    chainName: "Polygon Mumbai Testnet",
    rpcUrl: polygonRpcUrl,
    explorerUrl:explorerUrlPolygon,
    nativeCurrency: "Matic",
    symbol: "MATIC",
    chainId: polygonChainId,
  },
  {
    id: 3,
    name: "Avalanche",
    network: networkavala,
    chainName: "Avalanche Fuji Testnet",
    rpcUrl: avalancheRpcUrl,
    explorerUrl: explorerUrlAvalanche,
    nativeCurrency: "Avalanche",
    symbol: "AVAX",
    chainId: avalancheChainId,
  },
];

export const buyCoinMethods = async (contract, selectChains) => {
  const getData = {
    0: {
      method: contract.buyTokenWithbnb,
      address: process.env.REACT_APP_admin_address_bnb,
    },
    1: {
      method: contract.buyTokenWithETH,
      address: process.env.REACT_APP_admin_address_eth,
    },
    2: {
      method: contract.buyTokenWithMATIC,
      address:process.env.REACT_APP_admin_address_matic,
    },
    3: {
      method: contract.buyTokenWithAVAX,
      address: process.env.REACT_APP_admin_address_avala,
    },
  };

  const selectedChain = getData[selectChains];
  return selectedChain;
};


const buyChainConfigs = {
  0: {
    name: 'Binance Smart Chain',
    chainId: binanceChainId,
    // abi: bsc_abi,
    tokens: {
      '1': { address: bsc_ta_wbtc,abi:bsc_abi_wbtc, decimals: 18 },
      '2': { address: bsc_ta_weth,abi:bsc_abi_weth, decimals: 18 },
      '3': { address: bsc_ta_usdt, abi:bsc_abi_usdt,decimals: 18 },
      '4': { address: bsc_ta_usdc, abi:bsc_abi_usdc,decimals: 18 }
    }
  },
  1: {
    name: 'Ethereum',
    chainId: ethChainId,
    tokens: {
      '1': { address: eth_ta_wbtc, abi: eth_abi_wbtc, decimals: 8 },
      '2': { address: eth_ta_usdt, abi: eth_abi_usdt, decimals: 6 },
      '3': { address: eth_ta_usdc, abi: eth_abi_usdc, decimals: 6 }
    }
  },
  2: {
    name: 'Polygon',
    chainId: polygonChainId,
    tokens: {
      '1': { address: poly_ta_usdt, abi: poly_abi_usdt, decimals: 6 },
      '2': { address: poly_ta_usdc, abi: poly_abi_usdc, decimals: 6 }
    }
  },
  3: {
    name: 'Avalanche',
    chainId: avalancheChainId,
    tokens: {
      '1': { address: avala_ta_usdt, abi: avala_abi_usdt, decimals: 6 },
      '2': { address: avala_ta_usdc, abi: avala_abi_usdc, decimals: 6 }
    }
  }
};

export const processTransaction = async (selectChains, chainId, selectedCurrency, tokenInput) => {
  const chainConfig = buyChainConfigs[selectChains];
  
  if (!chainConfig) {
    return {status:false,message:"Invalid chain selected"};
  }

  if (chainId != chainConfig.chainId) {
    return {status:false,message:`Please change network to ${chainConfig.name}`};
  }

  const tokenConfig = chainConfig.tokens[selectedCurrency];
  
  if (!tokenConfig) {
    return {status:false,message:`Invalid currency selected`};
  }

  const { address: getTokenAddr, abi: chainabi = chainConfig.abi, decimals } = tokenConfig;
  let updatetAmt = Number(tokenInput) * 10 ** decimals;
  return {status:true,getTokenAddr,chainabi ,updatetAmt:parseInt(updatetAmt)}
};
