import { tronbaseurl } from "./BaseUrl"

//====================== auth ====================
export const checkUserApi="/check-user"
export const userRegisterApi="/user-register"
export const userTempWalletAddressApi="/user-temp-wallet-address"
export const checkReferralUrlApi="/check-referral-url"
export const getUserDetailapi="/get-user-detail"

//================ transaction ===================
export const addTransactinApi="/add-transaction"
export const getTransactionApi="/get-transaction"
export const getRecentActivityApi="/get-recentactivity"

//================= stage ===============
export const getStageApi="/get-stage"
export const tokenRaisedApi="/get-token-raised"

//=============== Gen wallet ===============
export const generateWalletApi="/generate-wallet-address"

//================== Dashboard =================
export const getUserDashboardApi="/get-user-dashboard"

//============== additional ==================
export const getYoutubeUrlApi="/additional-youtube"
export const getLiveTokenDateApi="/additional-live-token-date"