import { getStageApi, tokenRaisedApi } from "../components/constant/api";
import { myApi } from "./api";

export const stageApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getStage: builder.query({
        query: () => ({
          url: getStageApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
        providesTags: (_) => ["stage"],
      }),
      getTokenRaised: builder.query({
        query: () => ({
          url: tokenRaisedApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
        providesTags: (_) => ["stage"],
      }),
  }),
});

export const {useGetStageQuery,useGetTokenRaisedQuery} = stageApi;