import { getLiveTokenDateApi, getYoutubeUrlApi } from "../components/constant/api";
import { myApi } from "./api";

export const additionalApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getYoutubeUrl: builder.query({
        query: () => ({
          url: getYoutubeUrlApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
      }),
      getLiveTokenDate: builder.query({
        query: () => ({
          url: getLiveTokenDateApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
      }),
  }),
});

export const {useGetLiveTokenDateQuery,useGetYoutubeUrlQuery} = additionalApi;