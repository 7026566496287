import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import QRCode from "qrcode.react";
import copy from "copy-to-clipboard";
function GenWalletModal(props) {
  const [show, setShow] = useState(false);
  const [countdown, setCountdown] = useState(null);

  const endTime = new Date('2024-09-25T12:52:57.096');

  const handleClose = () => {
    props.setShowModal(false);
  };

  const copyToClipboard = () => {
    if (props.genWalletAddress) {
      copy(props.genWalletAddress);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  };
  console.log(props.endtime,"props.endtime")
  useEffect(() => {
    // Function to calculate the remaining time
    const calculateRemainingTime = () => {
      const now = new Date();
      const timeDifference = new Date(props.endtime) - now; // Difference in milliseconds
      return Math.max(timeDifference, 0); // Ensure no negative values
    };

    // Update countdown every second
    const interval = setInterval(() => {
      const remainingTime = calculateRemainingTime();
      setCountdown(remainingTime);

      // If time is up, hide modal
      if (remainingTime <= 0) {
        handleClose(false);
        clearInterval(interval); // Clear the interval once time is up
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [endTime]);

  // Function to format the countdown in mm:ss format
  const formatCountdown = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title >Scan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
            <div className="scan_box">
              <QRCode size={150} value={props.genWalletAddress} id="123456" />
            </div>
            </div>
            <div className="col-md-6">
              <div className="mb-4">
                <label className="form-label"> Amount</label>
                <input
                  readOnly
                  disabled
                  value={props.inputAmount}
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <label className="form-label"> Ukc token</label>
                <input
                  readOnly
                  disabled
                  value={props.ukcToken}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-12 mt-3 mb-4">
              <label>Wallet Address</label>
              <div className="position-relative">
              <input
                readOnly
                disabled
                value={props.genWalletAddress}
                className="form-control"
              />
              <span className="eye_icon" onClick={copyToClipboard}>
                <i class="bi bi-copy"></i>
              </span>
              {show && <span>Copied</span>}
            </div>
            <div className="mt-2">Transaction data will be fetched after 5-8mins</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {countdown !== null ? formatCountdown(countdown) : 'Calculating...'}
           <span className="ps-2">Close</span> 
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GenWalletModal;
