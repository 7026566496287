import React from "react";
import LedBy from "./LedBy";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tokenomics from "./Tokenomics";
import Referral from "./Referral";

function MiddleBanner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="logo333_section position-relative ovh ">
        <div className="text-center container">
          {" "}
          <img className="img-fluid" src="/assets/img/logo333.png" alt="ukc-man" data-aos="fade-up" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <h1 className=" fmenda mb-4  " data-aos="fade-right">
                Unlock the Power
                <br /> of UKCCOIN
              </h1>

              <div className="ultimate_box position-relative" data-aos="fade-right">
                <div className="ul_border color5"  >
                  <h4 className="MendaMed " >
                    {" "}
                    The Ultimate Cricket Wagering Platform
                  </h4>
                  <p className="mb-0">
                    Experience the first-ever wagering platform built
                    exclusively <br />
                    for cricket fans, accepting both crypto and card payments.
                  </p>
                  <img
                    className="aero position-absolute"
                    src="/assets/img/aero.png"
                    alt="aero"
                  />
                </div>
              </div>

              <div className="ultimate_box2 position-relative mt-4" data-aos="fade-right">
                <div className="row align-items-center">
                  <div className="col-md-7">
                    <h2 className=" fmenda mb-3 color5">
                      FIND YOUR
                      <br />
                      MATCH UP
                    </h2>
                    <p>
                      From international tournaments to domestic showdowns,
                      UKC333 offers unmatched access to cricket leagues
                      worldwide.
                    </p>
                  </div>
                  <div className="col-md-5" >
                    <img
                      className="img-fluid"
                      src="/assets/img/phone1.png"
                      alt="phone1"
                    />
                  </div>
                </div>
              </div>

              <div className="ultimate_box position-relative mt-4" data-aos="fade-up">
                <div className="ul_border ">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <h6 className="MendaMed tuu mb-0 text-md-end color5">
                        Powered By THE
                      </h6>
                    </div>
                    <div className="col-md-7">
                      <img
                        className="img-fluid"
                        src="/assets/img/bs.png"
                        alt="aero"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5" >
              <img className="phone2" src="/assets/img/phone2.png" alt="phone1" data-aos="fade-left" />
              <img
                className="place mt-4 img-fluid"
                src="/assets/img/place.png"
                alt="place" data-aos="fade-left"
              />
            </div>
          </div>
          <div className="position-relative win_box" data-aos="fade-up">
            <img className="w100" src="/assets/img/win.png" alt="win" />
            <div className="win_text">
              <h2 className=" fmenda mb-2 color5">WIN BIG</h2>
              <p className="mb-3">
                Bet with UKC tokens and enjoy revised stakes, extra rewards, and
                exclusive custom offers tailored just for you.
              </p>
              <a href="/#buysell" className="btn w_btn">
                <span className="d-flex align-items-center">
                  Buy now <i className="bi bi-arrow-right-short b_icon"></i>
                </span>{" "}
              </a>
            </div>
          </div>

          <div className="d-md-flex mt-4" data-aos="fade-up">
            <div className=" ms-md-auto">
              <div className="d-flex align-items-center red_box">
                <div className="red_box_border d-flex me-3">
                  <i className="bi bi-globe"></i>
                  <i className="bi bi-globe me-3 ms-3"></i>
                  <i className="bi bi-globe"></i>
                </div>
                <h5 className="mb-0 fmenda ">
                  Redefining Cricket
                  <br /> Engagement
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Referral/>
      <LedBy />
      <section className=" play_sec  position-relative text-center text-md-start">
        <img
          className="playcoin  position-absolute "
          src="/assets/img/playcoin.png"
          alt="playcoin" data-aos="fade-up"
        />
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-md-4 ms-auto position-relative ps-md-5">
              <img
                className="img-fluid "
                src="/assets/img/phone3.png"
                alt="playcoin"
              />
            </div>
            <div className="col-md-7 position-relative  mt-4 mt-md-0">
              <h1 className="fmenda mb-4 ">
                PLAY.
                <br />
                EARN.
                <br />
                <span className="color1"> SPEND.</span>
              </h1>
              <h4 className="MendaMed">
                The hottest Play to Earn Cricket Game.
                <br />
                All the stars at your fingertips.
              </h4>
            </div>

            <div className="col-md-10 m-auto">
              <img
                className="img-fluid phone4"
                src="/assets/img/phone4.png"
                alt="phone4"
              />
            </div>
          </div>

          <div className="row plan_icon text-center mt-5 pt-md-5">
            <div className="col-md-3 col-6" data-aos="fade-up" >
              <img
                className="img-fluid "
                src="/assets/img/plan1.png"
                alt="playcoin" 
              />
              <p>Instant Rewards</p>
            </div>
            <div className="col-md-3 col-6" data-aos="fade-up">
              <img
                className="img-fluid "
                src="/assets/img/plan2.png"
                alt="playcoin"
              />
              <p>Secure In-Game Purchases</p>
            </div>
            <div className="col-md-3 col-6" data-aos="fade-up">
              <img
                className="img-fluid "
                src="/assets/img/plan3.png"
                alt="playcoin"
              />
              <p>Voting Mechanism</p>
            </div>
            <div className="col-md-3 col-6" data-aos="fade-up">
              <img
                className="img-fluid "
                src="/assets/img/plan4.png"
                alt="playcoin"  
              />
              <p>Cross Platform</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pure_sec">
        <div className="container-fluid">
          <div className="d-md-flex align-items-center">
            <img className="" src="/assets/img/pl.png" alt="pl" />
            <small className="ms-md-3 me-md-auto mb-md-0">
              UKCCOIN OWN COMPLETE IP RIGHTS OF ALL CONTENT <br />
              OF THE EVENT AND STARS IN THE UKCVERSE{" "}
            </small>
            <img className="" src="/assets/img/pr.png" alt="pl" />
          </div>
        </div>
      </section>

      <section className="exc_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5" data-aos="fade-right">
              <img className="br10 img-fluid" src="/assets/img/exc.jpg" alt="pl" />
            </div>
            <div className="col-md-6 ms-auto ps-md-0">
              <img
                className="img-fluid mt-4 mb-4"
                src="/assets/img/exc_top.png"
                alt="pl" data-aos="fade-left"
              />
              <h2 className="fmenda mb-4 pt-5 ttu color5" data-aos="fade-left">Exclusive Content</h2>
              <img
                className="img-fluid"
                src="/assets/img/banterrooms.png"
                alt="pl" data-aos="fade-left"
              />
            </div>
          </div>
        </div>
        <div className="minia_box mt-5 position-relative" >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6" >
                <img className="img-fluid" src="/assets/img/merch.png" alt="pl" />
                <div className="minia_box_in mt-2 mt-md-5">
                  <h5 className="MendaMed mb-4" data-aos="fade-right">
                    UKC's premium line of legacy inspired merch. From hoodies to
                    caps, we've got it all.
                  </h5>
                  <h5 className="MendaMed" data-aos="fade-right">
                    Explore signature collections from the Legends and bag big
                    discounts when you buy with UKCCOIN.
                  </h5>

                  <img
                    className="img-fluid mt-4"
                    src="/assets/img/buy-ukc.png"
                    alt="pl" data-aos="fade-up"
                  />
                </div>
              </div>
              </div>
          <img
            className="ukc-t img-fluid"
            src="/assets/img/ukc-t.png"
            alt="ukc-t" data-aos="fade-up"
          />
            </div>
        
        </div>

        <div className="scrool_logo2 scrool_logo3  MendaMed">
          <Slider {...settings}>
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/mq1.png" alt="logo" />

            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/mq2.png" alt="logo" />
          </Slider>
        </div>

        <Tokenomics/>
      </section>
      <section className="pure_sec">
        <div className="container-fluid">
          <div className="d-md-flex align-items-center">
            <img className="" src="/assets/img/pl.png" alt="pl" />
            <small className="ms-md-3 me-md-auto mb-md-0">
              UKCCOIN OWN COMPLETE IP RIGHTS OF ALL CONTENT <br />
              OF THE EVENT AND STARS IN THE UKCVERSE{" "}
            </small>
            <img className="" src="/assets/img/pr.png" alt="pl" />
          </div>
        </div>
      </section>
    </>
  );
}

export default MiddleBanner;
