import React from 'react'

function YouTubeLink() {
  return (
    <>
      <section className="videosection position-relative text-center"> 
      <img className="img-fluid mt_img" src="/assets/img/ukc-man.png" alt="ukc-man" data-aos="fade-up" />
      <div className="video_btn_o" >
       <a  href="https://www.youtube.com/watch?v=_3sHkybuQk4&t=177s" className="video_btn m-auto" data-aos="flip-left" target="_blank"><i className="bi bi-play-fill"></i></a>
    </div>
  </section>
    </>
  )
}

export default YouTubeLink