import { generateWalletApi } from "../components/constant/api";
import { myApi } from "./api";

export const walletApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
   
    getgenWallet: builder.mutation({
        query: (post) => ({
          url: generateWalletApi,
          method: "POST",
          body:post
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
      }),
   
  }),
});

export const {useGetgenWalletMutation} = walletApi;