import React from "react";

function Footer() {
  return (
    <>
      <section className="footer  text-center text-md-start " id="contact">

        <div className="container-fluid">
          <div className="row     align-items-center">
            <div className="col-md-4 social_icon  text-md-start">
              <a href="https://x.com/ukccoinofficial" target="_blank">
                <i className="bi bi-twitter-x"></i>
              </a>
              <a href="https://www.instagram.com/ukccoinofficial?igsh=MTRnZDlwbzhiOXRtbg==" target="_blank"> 
                <i className="bi bi-instagram"></i>
              </a>
              <a href="https://t.me/UKCCoinOfficialGroup" target="_blank">
                <i className="bi bi-telegram"></i>
              </a>
            </div>
            <div className="col-md-4 text-center">
              <img src="/assets/img/logo-black.png" alt="Logo" />
            </div>
            <div className="col-md-4 text-md-end">
              © 2024 by UKCCOIN. All Rights Reserved
            </div>
          </div>
          <hr/>
          <div class="terms_link mb-3"><a href="/terms-conditions">Terms and conditions</a>
          <a className="me-0" href="/privacy-policy">Privacy Policy</a></div>
         <b className="fmenda "> Disclaimer:</b>
         <p className="mb-0">
Digital currencies may be unregulated in your jurisdiction. The value of digital currencies may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
</p>
        </div>
      </section>
    </>
  );
}

export default Footer;
