import React from "react";

function Faq() {
  return (
    <>
      <section className="faq position-relative">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-5">
              <h2 className="fmenda mt-4" data-aos="fade-up">
                <span>FAQS</span>
              </h2>
              <div id="accordion">
                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className=""
                      data-bs-toggle="collapse"
                      href="#collapseOne"
                    >
                      What is UKCCOIN?
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      UKC is the first Web3 fanbase for cricket fans and
                      players, powered by blockchain technology and with UKC
                      COIN as currency driving the ecosystem.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseTwo"
                    >
                      How can token holders generate passive income with UKC
                      COIN?
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Staking will be available upon launch for UKC holders, you
                      can earn additional income with the Power Staking
                      functionality while you also support your favourite
                      cricketer and participate in the community challenges and
                      Play to Earn game.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseThree"
                    >
                      When is UKC going to be available on exchanges?
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      After the presale ends, UKC will be listed on major Tier 1
                      exchanges. Wen Binance?👀
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseThree1"
                    >
                      How can I buy UKCCOIN in presale?
                    </a>
                  </div>
                  <div
                    id="collapseThree1"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      You can buy UKC via connecting your wallet to the website
                      and interacting with the buy widget you can purchase
                      UKCCOIN.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseThree2"
                    >
                      What payment methods are accepted for purchasing UKC?
                    </a>
                  </div>
                  <div
                    id="collapseThree2"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      The platform accepts payments in ETH, BNB, MATIC, AVAX,
                      SOL and other cryptocurrencies on the above networks.
                      Check the How to Buy section of our website.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header">
                    <a
                      className="collapsed "
                      data-bs-toggle="collapse"
                      href="#collapseThree3"
                    >
                      When do I receive my tokens?
                    </a>
                  </div>
                  <div
                    id="collapseThree3"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      The tokens sold during the presale stages are protected
                      via vesting contract. Presale investors will receive ther
                      tokens in tranches after a cliff period. Check the
                      dedicated section of our whitepaper.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7"></div>
          </div>
        </div>
        <img
          className="faq-man img-fluid"
          src="/assets/img/faq-man.png"
          alt="faq-man" data-aos="fade-left"
        />
      </section>
    </>
  );
}

export default Faq;
