import React, { useEffect, useState } from "react"; 
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";  

function TermsConditions() { 
  return (
    <>
      <Header />
      <section className="ex_box p70 pn_page_pt exc_sec pb-5">
        <div className="container  position-relative mb-md-5">
          <h2 className="hadding text-center mb-4 mb-md-5">
          Terms and  <span className="t_gr">Conditions</span>
          </h2>
<h3>1.1 Acceptance of Terms</h3>
 <p>These Terms & Conditions establish a legally binding agreement between you (whether personally or on behalf of an entity) and UKC ANCHOR LTD (referred to as the "Company," "UKC ANCHOR LTD," "we," "us," or "our"). These terms govern your access to and use of the ukccoin.io website, along with any other associated media forms, social media channels, or mobile websites (collectively referred to as the "platform"). By accessing the platform, you acknowledge that you have read, comprehended, and agreed to abide by all these Terms & Conditions. If you do not agree to these Terms of Use in their entirety, you are expressly prohibited from using the website.</p>

 <h3 className="mt-4"> 1.2 Legal Authorization</h3>
 <p> By accessing or using ukccoin.io, you affirm that you are of legal age and have the legal capacity to enter into this agreement. If you are accessing or using ukccoin.io on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
 </p>

 <h3 className="mt-4"> 1.3 Site Interface Disclaimer</h3>
 <p>ukccoin.io’s website provides access to a decentralized protocol on the Binance Smart Chain Network, allowing “you,” the user, to exchange cryptocurrency assets in the form of ETH, USDT, and BNB for UKC ANCHOR LTD’s utility token $UKC. Using the platform may require that “you” pay a fee on the UKC ANCHOR LTD platform to perform a transaction. You acknowledge and agree that UKC ANCHOR LTD has no control over any transactions, the method of payment of any transaction, or any actual payments of transactions. You must ensure that you have sufficient funds to complete any transaction on the platform before initiating such a transaction.
 </p>
 <h3 className="mt-4">2.1 Compliance with Laws </h3>
 <p> When using ukccoin.io, you agree to comply with all applicable laws, regulations, and third-party rights.</p>
 <h3 className="mt-4">2.2 Prohibited Activities
 </h3>
 <p>You agree not to engage in any illegal, fraudulent, or harmful activities using ukccoin.io, including but not limited to:
 </p>
 <ul className="list_abc">
  <li>Violating any applicable laws, regulations, or third-party rights.</li>
  <li>Collection or compiling data from the platform without permission.
</li>
  <li>Deceiving or tricking us or other users to obtain sensitive account information.</li>
  <li> Bypassing or tampering with security features of the platform.</li>
  <li> Misusing support services or submitting false reports.
  </li>
  <li>Using the platform for advertising or selling goods and services.
  </li>
  <li>Framing or linking to the platform without authorization.
  </li>
  <li>Uploading viruses or spam that disrupts platform usage.
  </li>
  <li> Removing copyright notices from content.</li>
  <li>Selling or transferring your profile.
  </li>
  <li>Engaging in cyberattacks or compromising system integrity.
  </li>
  <li>Copying or modifying the platform software without permission.
  </li>
  <li>Reverse engineering or disassembling platform software.</li>
  <li>Engaging in market manipulation or violating trading market regulations.  </li>
 </ul>
 <h3 className="mt-4">3.1 Availability </h3>
 <p>While we make every effort to ensure uninterrupted access to ukccoin.io, we cannot guarantee the continuous or error-free operation of our services. We reserve the right to temporarily or permanently suspend or terminate ukccoin.io for maintenance, upgrades, security, or other reasons, without prior notice.
 </p>
 <h3 className="mt-4"> 3.2 Services Disclaimer
 </h3>
 <p>ukccoin.io provides information and content on its platforms. However, ukccoin.io is not intended for distribution or use in jurisdictions or countries where such distribution or use would violate local laws or regulations or necessitate compliance with registration requirements. If you choose to access ukccoin.io from such locations, you do so voluntarily and assume sole responsibility for complying with applicable local laws.
 </p>
 <h3 className="mt-4">4.1 Ownership
 </h3>
 <p>Intellectual property found in the materials contained in the Website, as well as any other media form, social media channel, or mobile website related, linked, or otherwise connected thereto (collectively, the “platform”) are owned by or licensed to ukccoin.io, and are protected by applicable copyright and trademark laws. We grant users permission to download one copy of the materials for personal, non-commercial transitory use only.
 </p>
 <h3 className="mt-4">4.2 License
 </h3>
 <p> Subject to your compliance with these Terms, the Company grants a license, not a transfer of title. You agree not to use ukccoin.io for any commercial purposes without our prior written consent. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service and may be terminated by ukccoin.io at any time.
 </p>
 <h3 className="mt-4"> 5.1 Volatility
 </h3>
 <p> $UKC is subject to significant price volatility and market fluctuations. You understand and acknowledge the risks associated with investing or holding $UKC, and you agree that ukccoin.io shall not be responsible for any financial losses incurred.
 </p>
 <h3 className="mt-4"> 5.2 Third-party Services
 </h3>
 <p>The Service may provide links to third-party websites or services for your convenience. UKC ANCHOR LTD does not endorse or control these third-party services, and you agree that UKC ANCHOR LTD shall not be responsible for any content, products, or services provided by such third parties. For more information about third-party services, please review our Cookies and Privacy Policies.
 </p>
 <h3 className="mt-4"> 5.3 User Responsibility
 </h3>
 <p>You are solely responsible for the security and custody of your $UKC holdings, including but not limited to your private keys, passwords, and access codes. You agree to take appropriate measures to safeguard your $UKC holdings and protect them from unauthorized access or loss.
 </p>
 <h3 className="mt-4">6.1 Modifications </h3>
 <p>ukccoin.io reserves the right to modify or terminate the Service or these Terms at any time, with or without prior notice. It is your responsibility to review the Terms periodically for any changes. Your continued use of the Service after the modifications will constitute your acceptance of the revised Terms. </p>
 <h3 className="mt-4"> 6.2 Termination</h3>
 <p>ukccoin.io may suspend or terminate your access to the Service at any time, with or without cause, in its sole discretion. Upon termination, all licenses and rights granted to you under these Terms will immediately cease. </p>
 <h3 className="mt-4"> 7. Severability</h3>
 <p>Any term found in these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected. </p>
 <h3 className="mt-4"> 8. Entire Agreement</h3>
 <p> These Terms constitute the entire agreement between you and ukccoin.io regarding your use of ukccoin.io and supersede any prior agreements or understandings, whether oral or written.</p>
 <h3 className="mt-4">9. Contact Us </h3>
 <p> Questions, concerns, and inquiries regarding these Terms of Service can be directed to UKC ANCHOR LTD through this contact email: support@ukccoin.io. By using ukccoin.io, you agree to abide by these Terms of Service.</p>
 

        
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TermsConditions;
