import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./components/pages/home/Home";
import TransactionDetails from "./components/pages/transactiondetails/TransactionDetails";
import TermsConditions from "./components/pages/termsandconditions/TermsConditions";
import PrivacyPolicy from "./components/pages/privacypolicy/PrivacyPolicy";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/#signup" element={<Home />} />
          <Route path="/#buysell" element={<Home />} />
          <Route path="/#tokenomics" element={<Home />} />
          <Route path="/#refer" element={<Home />} />
          <Route path="/transaction" element={<TransactionDetails />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/:referral_code" element={<Home />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
